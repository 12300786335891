import React, { useState } from 'react'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

const getPhotos = () => {
  const photo = () => {
    const width = 400
    const height = 600
    const id = Math.floor(Math.random() * Math.floor(1000))
    const PHOTO_URL = `https://picsum.photos/id/${id}/${width}/${height}`
    return {
      src: PHOTO_URL,
      width,
      height,
    }
  }

  const photos = [photo()]

  for (let i = 0; i < 20; i++) {
    photos.push(photo())
  }
  return photos
}

const GalleryPageTemplate = () => {
  const photos = getPhotos()
  console.log(photos)

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index)
    setViewerIsOpen(true)
  }
  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
export default GalleryPageTemplate
